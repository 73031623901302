import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImageSlide from "../../components/ImageSlide"

const Container = () => (
  <Layout>
    <SEO title="Home" />
    <div className="home">
      <header
        className="color-bg light-typo padding-top-bottom bg-image detail-header"
        style={{
          backgroundImage:
            "url(/images/spaces/facility/bench/facility_bench_02.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <h1 className="page-title">야외테이블</h1>

          <p className="page-subtitle">
            따뜻한 햇볕, 깨끗하고 시원한 바람을 즐기며 식사나 차를 마셔보는 건
            어떠세요 ?
          </p>
        </div>
      </header>

      <article className="post single-post">
        <div className="container scrollimation fade-up">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <div className="post-media">
                <ImageSlide
                  imageList={[
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/spaces/facility/bench/facility_bench_01.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">야외 테이블</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/spaces/facility/bench/facility_bench_02.jpg"
                        alt=""
                      />
                      <p className="flex-caption text-center">야외 테이블</p>
                    </li>,
                    <li>
                      <img
                        className="img-responsive"
                        src="/images/spaces/facility/bench/facility_bench_03.png"
                        alt=""
                      />
                      <p className="flex-caption text-center">야외 테이블</p>
                    </li>,
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </Layout>
)

export default Container
